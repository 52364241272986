export function convertToAMPM(time24) {
    // 解析24小时制的时间
    const [hours, minutes] = time24.split(':');

    // 将小时转换为数字
    let hoursNum = parseInt(hours);

    // 判断是上午还是下午
    const ampm = hoursNum >= 12 ? 'PM' : 'AM';

    // 将小时调整为12小时制
    hoursNum = hoursNum % 12 || 12;

    // 将转换后的时间格式化为字符串
    const time12 = `${hoursNum}:${minutes} ${ampm}`;

    return time12;
}

//当天内手机端段的互补相同的约束
export const checkTimeConflicts1 = (dates) => {
    const dateRanges = {};

    for (const entry of dates) {
        let { name, timeStart, timeEnd } = entry;
        // 在调整`timeEnd`之前，确保它不会影响原始数据结构
        if (timeEnd === "00:00") {
            timeEnd = "24:00"; // 将"00:00"调整为"24:00"用于比较
        }
        // const adjustedTimeEnd = timeEnd === "00:00" ? "24:00" : timeEnd; // 将"00:00"调整为"24:00"用于比较

        if (!timeStart || !timeEnd) {
            return false; // 如果任何一个为空，直接返回false
        }

        if (!dateRanges[name]) {
            dateRanges[name] = [];
        }

        // 检查当前时间范围是否与已经存在的时间范围重叠
        if (dateRanges[name].some((range) => isTimeOverlap(range, timeStart, timeEnd))) {
            return false; // 有重叠，返回false
        }

        dateRanges[name].push({ timeStart, timeEnd });
    }

    return true; // 没有重叠，返回true
};

export const getDayOfWeek = (dateString) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const date = new Date(dateString);
    const dayOfWeek = date.getDay();
    return days[dayOfWeek];
}

//checkOverlap
export const checkOverlap = (timeSlots) => {
    // 检查开始时间和结束时间是否唯一，并且没有重叠
    for (let i = 0; i < timeSlots.length; i++) {
        const currentStartTime = new Date(timeSlots[i].dateStart);
        const currentEndTime = new Date(timeSlots[i].dateEnd);

        // 检查开始时间是否唯一
        for (let j = i + 1; j < timeSlots.length; j++) {
            const nextStartTime = new Date(timeSlots[j].dateStart);
            const nextEndTime = new Date(timeSlots[j].dateEnd);

            if (
                currentStartTime.getTime() === nextStartTime.getTime() ||
                currentEndTime.getTime() === nextEndTime.getTime() ||
                currentStartTime.getTime() === nextEndTime.getTime()
            ) {
                return false; // 开始时间、结束时间或者开始时间与其他结束时间相同，返回 false
            }

            // 检查是否有交叉
            if (
                (currentStartTime < nextEndTime && currentEndTime > nextStartTime) ||
                (nextStartTime < currentEndTime && nextEndTime > currentStartTime)
            ) {
                return false; // 有交叉，返回 false
            }
        }
    }

    return true; // 没有重叠和交叉，返回 true
};
//反转年月日
export const reverseDate = (dateString) => {
    const dateArray = dateString.split("-");
    const reversedDate = dateArray.reverse().join("-");
    return reversedDate;
}
//反转年月日 + 时分秒
export const reverseDateTime = (dateString) => {
    // 使用空格将日期和时间分割
    const [datePart, timePart] = dateString.split(" ");

    // 将日期部分按照"-"分割成数组
    const dateArray = datePart.split("-");

    // 将数组中的元素顺序反转
    const reversedDate = dateArray.reverse().join("-");

    // 重新组合日期和时间部分，并返回结果
    return `${reversedDate} ${timePart}`;
}



//当天内手机端段的互补相同的约束
export const checkTimeConflicts = (dates) => {
    const dateRanges = {};
    const currentDate = new Date();
    const formattedCurrentDate = currentDate.toISOString().split('T')[0];

    for (const entry of dates) {
        let { date, timeStart, timeEnd } = entry;
        // 在调整`timeEnd`之前，确保它不会影响原始数据结构
        if (timeEnd === "00:00") {
            timeEnd = "24:00"; // 将"00:00"调整为"24:00"用于比较
        }

        if (!timeStart || !timeEnd) {
            return false; // 如果任何一个为空，直接返回false
        }

        const startTime = formatTimeToDate(date, timeStart);
        const endTime = formatTimeToDate(date, timeEnd);

        // 检查是否为同一天以及时间是否早于当前时间
        if (!entry.id) {
            if (date === formattedCurrentDate && startTime < currentDate) {
                return false; // 如果是同一天且时间早于当前时间，返回false
            }
        }

        if (dateRanges[date]) {
            if (dateRanges[date].some((range) => isTimeOverlap(range, timeStart, timeEnd))) {
                return false; // 有重叠，返回false
            }
            dateRanges[date].push({ timeStart, timeEnd });
        } else {
            dateRanges[date] = [{ timeStart, timeEnd }];
        }
    }

    return true; // 没有重叠，返回true
};
//辅助checkTimeConflicts
const isTimeOverlap = (range, start, end) => {
    return (
        (start >= range.timeStart && start < range.timeEnd) ||
        (end > range.timeStart && end <= range.timeEnd)
    );
};

const formatTimeToDate = (date, time) => {
    const [hours, minutes] = time.split(':').map(Number);
    const result = new Date(date);
    result.setHours(hours, minutes);
    return result;
};


//查找日日历头尾
export const getCalendarDates = (today, arr) => {
    //获取当前日期
    // let today = new Date();
    console.log(today, "zzzz");

    //获取当月的第一天
    let firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    //获取当月的最后一天
    let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    //日历通常会从周日开始，所以需要获取这个月的第一天是星期几，然后再算出日历的开始日期
    //如果第一天是周二（getDay() === 2），那日历应该从前一周的周日开始。
    let calendarStart = new Date(
        firstDayOfMonth.setDate(firstDayOfMonth.getDate() - firstDayOfMonth.getDay())
    );

    //同理，我们也需要计算出日历的结束日期
    let calendarEnd = new Date(
        lastDayOfMonth.setDate(lastDayOfMonth.getDate() + (6 - lastDayOfMonth.getDay()))
    );

    //转化日期为 YYYY-MM-DD 格式
    function formatDate(date) {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    }
    console.log([formatDate(calendarStart), formatDate(calendarEnd)], "zzzz");

    return (arr = [formatDate(calendarStart), formatDate(calendarEnd)]);
};
//加减一个月变为一号
export const addOneMonthAndSetToFirstDay = (date, type) => {
    // 创建一个新的 Date 对象，以免直接修改传入的参数
    let oldDate = new Date(date);
    let newDate = new Date(date);
    console.log(selectDay(newDate), "一次进入newDate")

    

    // 月份加一
    if (type == "add") {
        newDate.setMonth(newDate.getMonth() + 1);
        // if (newDate.getMonth() == oldDate.getMonth()) {
        //     newDate.setMonth(newDate.getMonth() + 1)
        // }
    } else {
        newDate.setMonth(newDate.getMonth() - 1)
        if (newDate.getMonth() == oldDate.getMonth()) {
            newDate.setMonth(newDate.getMonth() - 1)
        }
    }
    
    // 如果是当前月份，保留当天日期
    // if (newDate.getMonth() === date.getMonth()) {
    //     newDate.setDate(date.getDate());
    // } else {
    //     // 如果不是当前月份，设置日期为一号
    //     newDate.setDate(1);
    // }
    // console.log(selectDay(newDate), "最终一次")

    return newDate;
};

//拿当前时间年月日
export const nowDay = () => {
    var currentDate = new Date();
    // 获取年、月和日的值
    var year = currentDate.getFullYear();
    var month = currentDate.getMonth() + 1; // 月份是从0开始的，因此需要加1
    var day = currentDate.getDate();

    // 将年、月、日组合成年月日格式
    return year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);

}

//拿指定时间年月日
export const selectDay = (currentDate) => {
    // 获取年、月和日的值
    var year = currentDate.getFullYear();
    var month = currentDate.getMonth() + 1; // 月份是从0开始的，因此需要加1
    var day = currentDate.getDate();

    // 将年、月、日组合成年月日格式
    return year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);

}

//GMT 时间格式转年月日
export const gtmTransform = (inputDateString) => {
    const inputDate = new Date(inputDateString);

    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0");
    const day = String(inputDate.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
};

export const formatDateTime = (dateTimeString) => {
    // 创建一个新的Date对象，从给定的时间字符串中解析时间
    const dateTime = new Date(dateTimeString);

    // 获取年份
    const year = dateTime.getFullYear();

    // 获取月份（注意：月份从0开始，所以要加1）
    const month = dateTime.getMonth() + 1;
    const formattedMonth = month < 10 ? '0' + month : month;

    // 获取日期
    const date = dateTime.getDate();
    const formattedDate = date < 10 ? '0' + date : date;

    // 获取小时
    const hours = dateTime.getHours();
    const formattedHours = hours < 10 ? '0' + hours : hours;

    // 获取分钟
    const minutes = dateTime.getMinutes();
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

    // 获取秒数
    const seconds = dateTime.getSeconds();
    const formattedSeconds = seconds < 10 ? '0' + seconds : seconds;

    // 构造格式化后的日期时间字符串
    const formattedDateTime = `${year}-${formattedMonth}-${formattedDate} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;

    // 返回格式化后的日期时间字符串
    return formattedDateTime;
}

// 示例用法：
const originalDateTimeString = "2024-03-06T16:28:23.000+08:00";
const formattedDateTime = formatDateTime(originalDateTimeString);
console.log(formattedDateTime); // 输出：2024-03-06 16:28:23
